export class MetadataStep {
  run(navigationInstruction, next) {
    let title;
    let metaDescription;
    if (navigationInstruction.getAllInstructions()[0].viewPortInstructions.default.childNavigationInstruction) {
      title = navigationInstruction.getAllInstructions()[0].viewPortInstructions.default.childNavigationInstruction.config.title;
      metaDescription = navigationInstruction.getAllInstructions()[0].viewPortInstructions.default.childNavigationInstruction.config.settings.metaDescription;
    } else {
      title = navigationInstruction.getAllInstructions()[0].config.title;
      metaDescription = navigationInstruction.getAllInstructions()[0].config.settings.metaDescription;
    }
    if (metaDescription) {
      //Set Description
      const matches = document.querySelectorAll('meta[name="description"]');
      if (matches.length) {
        const tag = matches[0];
        tag.content = metaDescription;
      } else {
        const tag = document.createElement('meta');
        tag.setAttribute('name', 'description');
        tag.content = metaDescription;
        document.getElementsByTagName('head')[0].appendChild(tag);
      }

      //Set og:description
      const ogDescriptionMatches = document.querySelectorAll('meta[property="og:description"]');
      if (ogDescriptionMatches.length) {
        const tag = ogDescriptionMatches[0];
        tag.content = metaDescription;
      } else {
        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = metaDescription;
        document.getElementsByTagName('head')[0].appendChild(ogDescription);
      }

      //Set twitter:description
      const twitterDescriptionMatches = document.querySelectorAll('meta[name="twitter:description"]');
      if (twitterDescriptionMatches.length) {
        const tag = twitterDescriptionMatches[0];
        tag.content = metaDescription;
      } else {
        const twitterDescription = document.createElement('meta');
        twitterDescription.setAttribute('property', 'twitter:description');
        twitterDescription.content = metaDescription;
        document.getElementsByTagName('head')[0].appendChild(twitterDescription);
      }
    }
    if (title) {
      //Set og:title
      const ogTitleMatches = document.querySelectorAll('meta[property="og:title"]');
      if (ogTitleMatches.length) {
        const tag = ogTitleMatches[0];
        tag.content = `${title} - Chicks Gold`;
      } else {
        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = `${title} - Chicks Gold`;
        document.getElementsByTagName('head')[0].appendChild(ogTitle);
      }

      //Set twitter:title
      const twitterTitleMatches = document.querySelectorAll('meta[name="twitter:title"]');
      if (twitterTitleMatches.length) {
        const tag = twitterTitleMatches[0];
        tag.content = `${title} - Chicks Gold`;
      } else {
        const twitterTitle = document.createElement('meta');
        twitterTitle.setAttribute('property', 'twitter:title');
        twitterTitle.content = `${title} - Chicks Gold`;
        document.getElementsByTagName('head')[0].appendChild(twitterTitle);
      }
    }
    return next();
  }
}