var _dec, _class;
import { inject } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { AuthenticationHandler } from 'resources/extensions/sso_extension';
import { ToastService } from 'services/toast-service';
export let AuthorizeStep = (_dec = inject(SessionService, ToastService), _dec(_class = class AuthorizeStep extends AuthenticationHandler {
  /**
   * @param {SessionService} sessionService
   * @param {ToastService} toastService
   */
  constructor(sessionService, toastService) {
    super(sessionService, toastService, null);
  }
  async run(navigationInstruction, next) {
    const requiresAuth = navigationInstruction.getAllInstructions().some(i => i.config.settings.auth);
    const isLoggedIn = await this.sessionService.isTokenValid(true);
    if (requiresAuth && !isLoggedIn && !navigationInstruction.queryString.includes('email') && !navigationInstruction.queryString.includes('token') && !navigationInstruction.queryString.includes('ticketKey') && !navigationInstruction.queryString.includes('messagePosition')) {
      this.handleCheckoutRedirection(null);
      return next.cancel();
    }
    return next();
  }
}) || _class);