/**
 * Enum for product category types
 * @readonly
 * @enum {string}
 */
export const ProductCategoryType = {
  Currency: 'Currency',
  Items: 'Items',
  Accounts: 'Accounts',
  Services: 'Services',
  Skins: 'Skins',
  UniqueNames: 'Unique Names',
  GiftCards: 'Gift Cards'
};
export const SellCategories = {
  [ProductCategoryType.Items.toLowerCase()]: 'Sell Item',
  [ProductCategoryType.Accounts.toLowerCase()]: 'Sell Account',
  [ProductCategoryType.Skins.toLowerCase()]: 'Sell Skins',
  [ProductCategoryType.Currency.toLowerCase()]: 'Sell Currency',
  [ProductCategoryType.UniqueNames.toLowerCase()]: 'Sell Unique Names',
  [ProductCategoryType.GiftCards.toLowerCase()]: 'Sell Gift Cards'
};

/**
 * Enum for SellCustomType
 * @readonly
 * @enum {number}
 */
export const SellCustomType = {
  Accounts: 0,
  Currency: 1
};

/**
 * @param {string} category
 * @returns {ProductCategoryType}
 */
export function getCategoryFromString(category) {
  if (category?.includes('skin')) {
    return ProductCategoryType.Skins;
  }
  if (category?.includes('account')) {
    return ProductCategoryType.Accounts;
  }
  return ProductCategoryType.Items;
}

/**
 * Enum for order types
 * @readonly
 * @enum {number}
 */
export const OrderTransactionType = {
  Purchased: 0,
  Sold: 1,
  Swap: 2
};

/**
 * Enum for account types
 * @readonly
 * @enum {number}
 */
export const AccountAcquiredType = {
  Unique: 1,
  Generic: 2,
  Resale: 1,
  InHouse: 2
};

/**
 * Enum for Filter types (NOTE: NumberCorePackages is temporary)
 * @readonly
 * @enum {number}
 */
export const FieldType = {
  String: 1,
  TextArea: 2,
  Number: 3,
  Checkbox: 4,
  Range: 5,
  Select: 6,
  NumberCorePackages: 7
};

/**
 * Enum for order chat status
 * @readonly
 * @enum {number}
 */
export const OrderChatStatus = {
  Open: 1,
  Closed: 2,
  Snoozed: 3
};

/**
 * Enum for the blacklist category
 * @readonly
 * @enum {number}
 */
export const BlacklistCategory = {
  Email: 1,
  PhoneNumber: 2,
  IpAddress: 3,
  CharacterName: 4,
  Region: 5
};

/**
 * Enum for the subscription payment plan
 * @readonly
 * @enum {number}
 */
export const SubscriptionPaymentPlan = {
  Monthly: 1,
  Annual: 2,
  Test: 3
};

/**
 * Enum for the transaction address type
 * @readonly
 * @enum {number}
 */
export const TransactionAddressType = {
  WalletAddress: 0,
  AccountAddress: 1,
  EmailAddress: 2
};

/**
 * @readonly
 * @enum {number}
 */
export const OrderChatMessageUserType = {
  Customer: 0,
  Staff: 1,
  System: 2
};

/**
 * Enum for the cart actions
 * @readonly
 * @enum {number}
 */
export const CartActionType = {
  AddProduct: 0,
  DeleteProduct: 1,
  Destroy: 2,
  Refresh: 3,
  Fulfill: 4
};

/**
 * @readonly
 * @enum {string}
 */
export const WebsiteShortCode = {
  ChicksGold: 'CG',
  ChicksX: 'CX',
  DivicaSales: 'DS',
  GamerTotal: 'GT',
  AribaGold: 'AG',
  AccountKings: 'AK',
  AdminPanel: 'AP'
};

/**
 * @readonly
 * @enum {number}
 */
export const VerificationIconState = {
  Pending: 0,
  Verified: 1
};

/**
 * @readonly
 * @enum {string}
 */
export const ToastType = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  TOOLTIP: 'text-only'
};