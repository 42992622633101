var _dec, _class;
import { inject } from 'aurelia-framework';
import { Helper } from 'resources/extensions/helper';
export let CapitalizeTextValueConverter = (_dec = inject(Helper), _dec(_class = class CapitalizeTextValueConverter {
  constructor(helper) {
    this.helper = helper;
  }
  toView(value, type) {
    if (type === void 0) {
      type = 'first';
    }
    return this.helper.toCapitalize(value, type);
  }
}) || _class);