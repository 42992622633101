var _dec, _class;
import { inject } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { ToastService } from 'services/toast-service';
import { Router } from 'aurelia-router';
import { baseUrl, chicksGroupBaseUrl, clientId } from 'environment';
import { ToastType } from 'resources/helpers/enums';
import { parseAuthQueryParams, redirectToSSO } from '@chicksgroup/helpers';
export let AuthenticationHandler = (_dec = inject(SessionService, ToastService, Router), _dec(_class = class AuthenticationHandler {
  /**
   * @param {SessionService} sessionService
   * @param {ToastService} toastService
   * @param {Router} router
   */
  constructor(sessionService, toastService, router) {
    /**
     * @type {SessionService}
     */
    this.sessionService = void 0;
    /**
     * @type {ToastService}
     */
    this.toastService = void 0;
    /**
     * @type {Router}
     */
    this.router = void 0;
    this.sessionService = sessionService;
    this.toastService = toastService;
    this.router = router;
  }
  async handleSSO() {
    const query = parseAuthQueryParams();
    let handledToken = false;
    try {
      const storedNonce = window.localStorage.getItem('nonce');
      const token = query.get('token');
      const nonce = query.get('nonce');
      if (!token || storedNonce && !nonce) {
        return;
      }
      const storedVerifier = window.localStorage.getItem('codeVerifier');
      handledToken = true;
      if (storedNonce !== nonce) {
        void this.toastService.showToast(ToastType.ERROR, 'Failed to validate sign in request. Please try again.');
        return;
      }
      const response = await this.sessionService.validateAuthorizationCode(token, clientId(), storedVerifier);
      if (!response?.token) {
        return;
      }
      this.sessionService.saveToken(response.token);
      this.sessionService.destroyActiveReferralCode();
      this.sessionService.removeAuthReferralLink();
      sessionStorage.setItem('update_cart', 'true');
    } catch (e) {
      console.log(e);
    } finally {
      if (handledToken) {
        window.location.href = window.location.href.split('#')[0];
      }
      localStorage.removeItem('nonce');
      localStorage.removeItem('codeVerifier');
    }
  }

  /**
   * @param {string} route
   * @returns {Promise<void>}
   * @private
   */
  async _doLocalLogin(route) {
    const email = prompt('email');
    const password = prompt('password');
    this.sessionService.login({
      email,
      password,
      skipChecks: true,
      token: ''
    }).then(loginResult => {
      this.sessionService.validateAuthorizationCode(loginResult.authorizationCode, clientId()).then(codeResponse => {
        if (!codeResponse?.token) {
          return;
        }
        this.sessionService.saveToken(codeResponse.token);
        location.reload();
      }).catch(err => {
        console.error(err);
        this.handleCheckoutRedirection(route);
      });
    }).catch(err => {
      console.error(err);
      this.handleCheckoutRedirection(route);
    });
  }

  /**
   * @param {string | null} route
   * @param {string | null} referralLink
   * @param {string | null} authenticationRoute
   * @param {string | null} refLink
   */
  async handleCheckoutRedirection(route, referralLink, authenticationRoute, refLink) {
    if (route === void 0) {
      route = 'cart';
    }
    if (referralLink === void 0) {
      referralLink = null;
    }
    if (authenticationRoute === void 0) {
      authenticationRoute = null;
    }
    if (refLink === void 0) {
      refLink = null;
    }
    if (this.sessionService.isLoggedIn() && route) {
      this.router.navigateToRoute(route);
      return;
    }
    if (baseUrl().includes('localhost')) {
      const skipChecks = confirm('skip sso?');
      if (skipChecks) {
        await this._doLocalLogin(route);
        return;
      }
    }
    void redirectToSSO({
      authenticationRoute,
      route,
      refLink,
      referralLink,
      ssoUrl: chicksGroupBaseUrl(),
      clientId: clientId()
    });
  }
}) || _class);