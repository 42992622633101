/* eslint-disable no-extend-native */
export class TypeExtensions {
  implement(helper) {
    // For Array and String
    const excludes = new BuiltProperty('excludes', function (val) {
      return !this.includes(val);
    });

    // For String And Number
    const parseFunctions = [new BuiltProperty('toFloat', function () {
      return parseFloat(this);
    }), new BuiltProperty('toInt', function () {
      return parseInt(this);
    })];
    const methodExtensions = [new CgPrototype(String.prototype, [excludes, ...parseFunctions, new BuiltProperty('isProfane', function () {
      return helper.isProfane(this);
    }), new BuiltProperty('hasLetters', function () {
      return /[a-zA-Z]/g.test(this);
    }), new BuiltProperty('isFile', function (noRoute) {
      if (noRoute === void 0) {
        noRoute = false;
      }
      return helper.isFile(this, noRoute);
    }), new BuiltProperty('toPascal', function () {
      return helper.toPascal(this);
    }), new BuiltProperty('toCapitalCase', function (type) {
      if (type === void 0) {
        type = 'first';
      }
      return helper.toCapitalize(this, type);
    }), new BuiltProperty('toRoute', function () {
      return helper.toRoute(this);
    }), new BuiltProperty('removeSelectorSymbol', function () {
      return helper.removeSelectorSymbol(this);
    })]), new CgPrototype(Array.prototype, [excludes, new BuiltProperty('copy', function () {
      return helper.copyArrayOfObjects(this);
    }), new BuiltProperty('pushTruthy', function (value) {
      if (value) this.push(value);
      return this;
    }), new BuiltProperty('getUnique', function (prop) {
      return helper.uniqueByProperty(this, prop);
    }), new BuiltProperty('reversed', function () {
      return this.copy().reverse();
    })]), new CgPrototype(Number.prototype, [...parseFunctions]), new CgPrototype(Object.prototype, [new BuiltProperty('enumName', function (value) {
      return helper.getEnumName(this, value);
    }), new BuiltProperty('setProp', function (str, value) {
      this[str] = value;
      return this;
    }), new BuiltProperty('isEmpty', function () {
      return this && helper.isObjectEmpty(this);
    }), new BuiltProperty('mapper', function (callback) {
      return helper.objectMap(this, callback);
    })])];
    methodExtensions.forEach(proto => {
      proto.props.forEach(prop => {
        this.buildDefault(proto.type, prop.name, prop.callback);
      });
    });
  }
  buildDefault(type, name, callback) {
    Object.defineProperty(type, name, {
      value: callback,
      writable: true,
      configurable: true
    });
  }
}
class CgPrototype {
  constructor(type, props) {
    this.type = type;
    this.props = props;
  }
}
class BuiltProperty {
  constructor(name, callback) {
    this.name = name;
    this.callback = callback;
  }
}