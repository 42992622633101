var _dec, _class;
import { inject } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { ToastService } from 'services/toast-service';
import { EventAggregator } from 'aurelia-event-aggregator';
export let ErrorPagesRedirection = (_dec = inject(ToastService, SessionService, EventAggregator), _dec(_class = class ErrorPagesRedirection {
  constructor(toastService, sessionService, eventAggregator) {
    this.handleRateLimitHttpStatus = async response => {
      const defaultResponse = Response.json({}, {
        status: 200
      });
      const currentUrl = window.location.href;
      const encodedUrl = encodeURIComponent(currentUrl);
      if (response?.status === 429 && window.location.pathname.includes('/rate-limit')) return defaultResponse;
      if (response?.status === 429 && this.sessionService.getRateLimitToastFired()) {
        this._redirectToRateLimit();
        return defaultResponse;
      }
      if (response?.status === 429 && !this.sessionService.getRateLimitToastFired()) {
        if (document.visibilityState === 'visible') {
          !window.location.pathname.includes('/rate-limit') && this.handleRateLimitBanner(encodedUrl);
        } else {
          this._redirectToRateLimit();
        }
        return defaultResponse;
      }
    };
    this.toastService = toastService;
    this.sessionService = sessionService;
    this.eventAggregator = eventAggregator;
  }
  handleRateLimitToken(response) {
    const item = JSON.parse(this.sessionService.getRateLimitToastFired());
    const now = new Date();
    if (window.location.pathname.includes('/rate-limit')) return response;
    if (item && now.getTime() < item?.expiry) {
      this._redirectToRateLimit();
    }
    if (item && now.getTime() > item?.expiry) {
      this.sessionService.removeRateLimitToastFired();
    }
  }
  handleRateLimitBanner(encodedUrlSrc) {
    if (this.sessionService.getRateLimitToastFired()) return;
    if (document.readyState !== 'loading') {
      setTimeout(() => {
        this.eventAggregator.publish('banner-updated', {
          stateBanner: 'default',
          text: 'You are being rate limited.'
        });
      }, 2500);
    }
    document.addEventListener('click', () => {
      const item = JSON.stringify(this._createNewTempToken(1));
      this.sessionService.setRateLimitToastFired(item);
      this._redirectToRateLimit(encodedUrlSrc);
    }, {
      once: true
    });
  }
  _redirectToRateLimit(encodedUrlSrc) {
    const currentUrl = window.location.href;
    const encodedUrl = encodeURIComponent(currentUrl);
    window.location.href = `/rate-limit?redirectTo=${encodedUrlSrc ? encodedUrlSrc : encodedUrl || '/'}`;
  }
  _createNewTempToken(minutes) {
    if (minutes === void 0) {
      minutes = 0.5;
    }
    const now = new Date();
    const expiryTime = now.getTime() + minutes * 60 * 1000;
    const item = {
      value: true,
      expiry: expiryTime
    };
    return item;
  }
  handleSiteDownBanner(encodedUrlSrc) {
    const currentUrl = window.location.href;
    const encodedUrl = encodeURIComponent(currentUrl);
    if (this.sessionService.getSiteDownToastFired()) return;
    if (document.readyState !== 'loading') {
      setTimeout(() => {
        this.eventAggregator.publish('banner-updated', {
          stateBanner: 'default',
          text: 'Chicksgold is currently down. To check status, please',
          url: 'https://status.chicksgold.com/'
        });
      }, 2500);
    }
    document.addEventListener('click', event => {
      const elementToIgnore = document.querySelector('#cds-banner');
      if (event.target instanceof Node) {
        if (event.target !== elementToIgnore && !elementToIgnore?.contains(event.target)) {
          this.sessionService.setSiteDownToastFired(true);
          window.location.href = `/site-down?redirectTo=${encodedUrlSrc ? encodedUrlSrc : encodedUrl || '/'}`;
        }
      }
    });
  }
}) || _class);